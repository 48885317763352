import clsx from "clsx"

import {colors} from "~/types/theme"

type Stroke = keyof typeof colors | "none" | "currentColor"
type IconProps = JSX.IntrinsicElements["svg"] & {
  direction?: "up" | "right" | "down" | "left"
  stroke?: Stroke
  fill?: Stroke
  viewBox?: string
  size?: "sm" | "lg"
}

const isColor = (stroke?: Stroke): stroke is keyof typeof colors => {
  return !!stroke && stroke in colors
}

const getIconStroke = (stroke?: Stroke) => {
  if (!stroke) {
    return "currentColor"
  }

  return isColor(stroke) ? colors[stroke] : stroke
}

const Icon = ({
  children,
  className,
  fill = "currentColor",
  viewBox = "0 0 32 32",
  size,
  width,
  height,
  stroke,
  ...props
}: IconProps) => {
  const sizes = {
    sm: "32",
    lg: "52",
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size ? sizes[size] : width || sizes["sm"]}
      height={size ? sizes[size] : height || sizes["sm"]}
      {...props}
      fill={fill}
      stroke={getIconStroke(stroke)}
      className={clsx(className)}
    >
      {children}
    </svg>
  )
}

export const IconHamburger = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M5 9H27"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M5 15.7H27"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M5 22.46H27"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconChevron = ({
  direction = "down",
  stroke,
  className,
  ...props
}: IconProps) => {
  let rotate

  switch (direction) {
    case "down":
      rotate = "rotate-0"
      break
    case "up":
      rotate = "rotate-180"
      break
    case "left":
      rotate = "rotate-90"
      break
    case "right":
      rotate = "-rotate-90"
      break
    default:
      rotate = "rotate-0"
  }

  return (
    <Icon
      {...props}
      className={clsx(rotate, className)}
      viewBox="0 0 32 32"
      fill="none"
      stroke={stroke}
    >
      <path
        d="M10.0001 13L16.4401 19.17L22.8701 13"
        strokeWidth="2"
        strokeMiterlimit="10"
        stroke={getIconStroke(stroke)}
      />
    </Icon>
  )
}

export const IconArrow = ({
  direction = "down",
  stroke,
  className,
  ...props
}: IconProps) => {
  let rotate

  switch (direction) {
    case "down":
      rotate = "rotate-0"
      break
    case "up":
      rotate = "rotate-180"
      break
    case "left":
      rotate = "rotate-90"
      break
    case "right":
      rotate = "-rotate-90"
      break
    default:
      rotate = "rotate-0"
  }

  return (
    <Icon
      {...props}
      className={clsx(rotate, className)}
      viewBox="0 0 32 32"
      fill="none"
      stroke={stroke}
    >
      <path
        d="M18.3599 10.71L24.0799 16.43L18.3599 22.16"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23.46 16.43H6"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconClose = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M23.56 9L9 23.56"
        stroke={getIconStroke(stroke)}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M23.56 23.56L9 9"
        stroke={getIconStroke(stroke)}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconPlus = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M8.71484 16H23.2848"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16 8.71497L16 23.285"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconMinus = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M8.71484 16H23.2848"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconSearch = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M15.16 24.32C20.2189 24.32 24.32 20.2189 24.32 15.16C24.32 10.1011 20.2189 6 15.16 6C10.1011 6 6 10.1011 6 15.16C6 20.2189 10.1011 24.32 15.16 24.32Z"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.9102 21.35L26.2202 25.66"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconCircleCheck = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M16.03 29.06C23.2263 29.06 29.06 23.2263 29.06 16.03C29.06 8.83373 23.2263 3 16.03 3C8.83373 3 3 8.83373 3 16.03C3 23.2263 8.83373 29.06 16.03 29.06Z"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.3798 12.09L14.1498 20.74L10.0298 17.3"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconStar = ({stroke, fill, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M16.3948 4.99884L19.1717 12.3783C19.2312 12.5397 19.384 12.6416 19.5538 12.6501L27.4343 13.0067C27.825 13.0237 27.9863 13.5162 27.6806 13.7625L21.5155 18.6878C21.3796 18.7897 21.3287 18.968 21.3711 19.1294L23.4601 26.7382C23.562 27.1118 23.1459 27.4175 22.8147 27.2052L16.225 22.8658C16.0806 22.7724 15.9023 22.7724 15.758 22.8658L9.16822 27.2052C8.83704 27.4175 8.42093 27.1118 8.52284 26.7382L10.6119 19.1294C10.6543 18.968 10.6034 18.7897 10.4675 18.6878L4.31934 13.7795C4.01363 13.5332 4.17497 13.0407 4.5656 13.0237L12.4461 12.667C12.6159 12.667 12.7603 12.5482 12.8282 12.3953L15.5966 4.99884C15.7325 4.63369 16.2505 4.63369 16.3948 4.99884Z"
        fill={fill ? getIconStroke(fill) : undefined}
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconCirlce = ({stroke, fill, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M16.4395 25C21.41 25 25.4395 20.9706 25.4395 16C25.4395 11.0294 21.41 7 16.4395 7C11.4689 7 7.43945 11.0294 7.43945 16C7.43945 20.9706 11.4689 25 16.4395 25Z"
        fill={fill ? getIconStroke(fill) : undefined}
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconFilter = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M4 11H16.46"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.46 11H27.16"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6699 21.2H27.1599"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M4 21.2H8.81"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M19.085 14C20.7418 14 22.085 12.6569 22.085 11C22.085 9.34315 20.7418 8 19.085 8C17.4281 8 16.085 9.34315 16.085 11C16.085 12.6569 17.4281 14 19.085 14Z"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9741 24.2C13.631 24.2 14.9741 22.8568 14.9741 21.2C14.9741 19.5431 13.631 18.2 11.9741 18.2C10.3173 18.2 8.97412 19.5431 8.97412 21.2C8.97412 22.8568 10.3173 24.2 11.9741 24.2Z"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconUser = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M7.46191 25.9074V25.4816C7.46191 21.9756 10.2975 19.1401 13.8034 19.1401H19.0668C22.5728 19.1401 25.4083 21.9756 25.4083 25.4816V25.9074"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.4397 16.3226C19.1115 16.3226 21.2774 14.1568 21.2774 11.485C21.2774 8.81323 19.1115 6.64734 16.4397 6.64734C13.7679 6.64734 11.6021 8.81323 11.6021 11.485C11.6021 14.1568 13.7679 16.3226 16.4397 16.3226Z"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconCart = ({stroke, ...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M21.8711 26.2769C22.6947 26.2769 23.3623 25.6093 23.3623 24.7857C23.3623 23.9621 22.6947 23.2945 21.8711 23.2945C21.0475 23.2945 20.3799 23.9621 20.3799 24.7857C20.3799 25.6093 21.0475 26.2769 21.8711 26.2769Z"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5108 26.2769C15.3343 26.2769 16.002 25.6093 16.002 24.7857C16.002 23.9621 15.3343 23.2945 14.5108 23.2945C13.6872 23.2945 13.0195 23.9621 13.0195 24.7857C13.0195 25.6093 13.6872 26.2769 14.5108 26.2769Z"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M24.7303 20.6103H13.5023C12.6952 20.6103 11.9935 20.0664 11.8005 19.277L8.5549 6.27698H5.41455"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M10.5544 16.8998H23.4228C24.2298 16.8998 24.9316 16.3559 25.1246 15.5665L26.5719 9.76822C26.6421 9.48752 26.4316 9.22437 26.1421 9.22437H9.15967"
        stroke={getIconStroke(stroke)}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconShield = ({...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 52 52" fill="none" stroke="none">
      <path
        d="M26 47.86C49.96 41.69 47.02 7.24002 47.02 7.24002C45.96 7.44002 34.24 9.10001 26 4.14001V47.87V47.86Z"
        fill="#EAF6F2"
      />
      <path
        d="M25.9999 4.13C17.7699 9.09 6.03988 7.43 4.97988 7.23C4.97988 7.23 2.03988 41.68 25.9999 47.85C49.9599 41.68 47.0199 7.23 47.0199 7.23C45.9599 7.43 34.2399 9.09 25.9999 4.13Z"
        stroke="#30343A"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23.7799 33.02L37.2799 19.52L33.8899 16.13L23.7799 26.24L20.3299 22.79L16.9399 26.18L23.7799 33.02Z"
        stroke="#3FE3BC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconScale = ({...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 52 52" fill="none" stroke="none">
      <path
        d="M28.91 40.62H49.48L11.5 2.63995V23.2V40.62H28.91Z"
        fill="#EAF6F2"
      />
      <path
        d="M17.91 32.2H4.75C3.64543 32.2 2.75 33.0954 2.75 34.2V47.36C2.75 48.4646 3.64543 49.36 4.75 49.36H17.91C19.0146 49.36 19.91 48.4646 19.91 47.36V34.2C19.91 33.0954 19.0146 32.2 17.91 32.2Z"
        stroke="#3FE3BC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M24.91 44.62H43.47C44.57 44.62 45.47 43.72 45.47 42.62V8.63995C45.47 7.53995 44.57 6.63995 43.47 6.63995H9.5C8.4 6.63995 7.5 7.53995 7.5 8.63995V27.2"
        stroke="#30343A"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M38.81 18.64V13.31H33.48"
        stroke="#30343A"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M38.81 13.31L23.75 28.37"
        stroke="#30343A"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconBulb = ({...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 52 52" fill="none" stroke="none">
      <g clipPath="url(#clip0_743_8401)">
        <path
          d="M35.5 36.83C44.6127 36.83 52 29.4427 52 20.33C52 11.2173 44.6127 3.82996 35.5 3.82996C26.3873 3.82996 19 11.2173 19 20.33C19 29.4427 26.3873 36.83 35.5 36.83Z"
          fill="#EAF6F2"
        />
        <path
          d="M25.6401 38.07L26.6001 31.82C26.8201 30.53 27.6001 29.58 28.8401 29.58C30.0801 29.58 31.0801 30.58 31.0801 31.82C31.0801 33.06 30.0801 34.06 28.8401 34.06H18.6001C17.3601 34.06 16.3601 33.05 16.3601 31.82C16.3601 30.59 17.3601 29.58 18.6001 29.58C19.8401 29.58 20.6201 30.53 20.8401 31.82L21.8001 38.07"
          stroke="#3FE3BC"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M28.1101 50.28H19.3301C18.2301 50.28 17.3301 49.38 17.3301 48.28V45.3H30.1101V48.28C30.1101 49.38 29.2101 50.28 28.1101 50.28Z"
          stroke="#30343A"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M40.6402 23.7C40.6402 14.08 32.6202 6.33001 22.9002 6.78001C8.8902 7.42001 1.6402 24.45 10.9702 34.84C13.1102 37.25 14.4402 40.24 14.7102 43.41C14.8002 44.47 15.6402 45.3 16.7102 45.3H30.7502C31.7902 45.3 32.6302 44.49 32.7402 43.46C33.0802 40.21 34.4902 37.15 36.6402 34.63C39.1402 31.68 40.6502 27.88 40.6502 23.71L40.6402 23.7Z"
          stroke="#30343A"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M36.1101 7.87001L38.3701 4.76001"
          stroke="#3FE3BC"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M43.78 18.41L47.43 17.23"
          stroke="#3FE3BC"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M43.78 31.45L47.43 32.64"
          stroke="#3FE3BC"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M3.65 31.45L0 32.64"
          stroke="#3FE3BC"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M3.65 18.41L0 17.23"
          stroke="#3FE3BC"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M11.3101 7.87001L9.06006 4.76001"
          stroke="#3FE3BC"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M23.71 3.84V0"
          stroke="#3FE3BC"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_743_8401">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export const IconJourney = ({...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 52 52" fill="none" stroke="none">
      <path d="M41.87 14.92H10.27V32.71H41.87V14.92Z" fill="#EAF6F2" />
      <path
        d="M20.2699 8.45999H37.7899C42.4999 8.45999 46.3099 12.27 46.3099 16.98C46.3099 21.69 42.4999 25.5 37.7899 25.5H14.2099C9.49994 25.5 5.68994 29.31 5.68994 34.02C5.68994 38.73 9.49994 42.54 14.2099 42.54H31.1199"
        stroke="#30343A"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.54 46.31L31.31 42.54L27.54 38.77"
        stroke="#30343A"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M44.3101 37.54H38.3101C37.2055 37.54 36.3101 38.4354 36.3101 39.54V45.54C36.3101 46.6445 37.2055 47.54 38.3101 47.54H44.3101C45.4146 47.54 46.3101 46.6445 46.3101 45.54V39.54C46.3101 38.4354 45.4146 37.54 44.3101 37.54Z"
        stroke="#3FE3BC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.27 12.46C12.4792 12.46 14.27 10.6691 14.27 8.45999C14.27 6.25085 12.4792 4.45999 10.27 4.45999C8.06088 4.45999 6.27002 6.25085 6.27002 8.45999C6.27002 10.6691 8.06088 12.46 10.27 12.46Z"
        stroke="#30343A"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}

export const IconLogo = ({...props}: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 160 26" fill="none" stroke="none">
      <path
        d="M12.8989 25.6171L0.397536 13.0146C0.158368 12.7712 0.022624 12.4468 0.019392 12.1061C0.01616 10.3799 0 4.97417 0 3.64707V0.811182C0 0.363409 0.361984 0 0.807999 0H23.9265C24.3725 0 24.7345 0.363409 24.7345 0.811182V3.13116C24.7345 3.4167 24.505 3.64707 24.2206 3.64707H4.14342C3.859 3.64707 3.62953 3.87745 3.62953 4.16299V11.0937C3.62953 11.3793 3.859 11.6096 4.14342 11.6096H7.19442C7.47884 11.6096 7.70831 11.3793 7.70831 11.0937V8.25459C7.70831 7.96905 7.93778 7.73867 8.2222 7.73867H11.8517C12.1361 7.73867 12.3656 7.96905 12.3656 8.25459V17.8428C12.3656 17.9531 12.4076 18.0569 12.4852 18.1348L16.564 22.2361C16.6415 22.314 16.745 22.3562 16.8549 22.3562H20.591C20.8755 22.3562 21.1049 22.1258 21.1049 21.8403V18.7772C21.1049 18.4917 20.8755 18.2613 20.591 18.2613H16.4444V8.25459C16.4444 7.96905 16.6771 7.73867 16.9615 7.73867H24.2238C24.5082 7.73867 24.7377 7.96905 24.7377 8.25459V25.1888C24.7377 25.6366 24.3757 26 23.9297 26C21.8515 26 15.6752 26 13.8168 26C13.4742 26 13.1445 25.8637 12.9021 25.6171H12.8989Z"
        fill="#3FE3BC"
      />
      <path
        d="M117.063 8.97806H105.841L102.073 12.7614V6.83005C102.073 6.60292 101.889 6.41797 101.662 6.41797H99.6101C99.3839 6.41797 99.1996 6.60292 99.1996 6.83005V19.199C99.1996 19.4261 99.3839 19.611 99.6101 19.611H101.666C101.889 19.611 102.073 19.4261 102.076 19.2022L102.105 16.8108L107.06 11.8788H114.6V19.1957C114.6 19.4228 114.784 19.6078 115.011 19.6078H117.063C117.289 19.6078 117.473 19.4228 117.473 19.1957V9.38689C117.473 9.15976 117.289 8.97481 117.063 8.97481V8.97806Z"
        fill="#1D2024"
      />
      <path
        d="M123.068 16.7233H120.909C120.711 16.7233 120.55 16.8845 120.55 17.0834V19.2509C120.55 19.4498 120.711 19.6111 120.909 19.6111H123.068C123.266 19.6111 123.427 19.4498 123.427 19.2509V17.0834C123.427 16.8845 123.266 16.7233 123.068 16.7233Z"
        fill="#3FE3BC"
      />
      <path
        d="M56.7926 6.41797H38.3153C38.0891 6.41797 37.9048 6.60292 37.9048 6.83005V11.9827C37.9048 12.2098 38.0891 12.3948 38.3153 12.3948H40.3676C40.5939 12.3948 40.7781 12.2098 40.7781 11.9827V9.30253H44.0618V16.548L47.0385 19.611H55.251C55.4772 19.611 55.6614 19.4261 55.6614 19.199V17.1385C55.6614 16.9114 55.4772 16.7265 55.251 16.7265H46.935V9.30578H56.7894C57.0156 9.30578 57.1999 9.12083 57.1999 8.8937V6.83329C57.1999 6.60616 57.0156 6.42121 56.7894 6.42121L56.7926 6.41797Z"
        fill="#1D2024"
      />
      <path
        d="M95.7091 6.418L83.9737 6.38879L80.9098 9.40964V16.5513L83.9026 19.6143H94.6813C94.9076 19.6143 95.0918 19.4294 95.0918 19.2022V17.1418C95.0918 16.9147 94.9076 16.7297 94.6813 16.7297H83.7992V9.30905H95.7059C95.9321 9.30905 96.1163 9.1241 96.1163 8.89697V6.83332C96.1163 6.60619 95.9321 6.42124 95.7059 6.42124L95.7091 6.418Z"
        fill="#1D2024"
      />
      <path
        d="M141.306 6.418L129.571 6.38879L126.507 9.40964V16.5513L129.5 19.6143H140.278C140.505 19.6143 140.689 19.4294 140.689 19.2022V17.1418C140.689 16.9147 140.505 16.7297 140.278 16.7297H129.396V9.30905H141.303C141.529 9.30905 141.713 9.1241 141.713 8.89697V6.83332C141.713 6.60619 141.529 6.42124 141.303 6.42124L141.306 6.418Z"
        fill="#1D2024"
      />
      <path
        d="M159.593 6.418L147.857 6.38879L144.793 9.40964V16.5513L147.786 19.6143H158.565C158.791 19.6143 158.975 19.4294 158.975 19.2022V17.1418C158.975 16.9147 158.791 16.7297 158.565 16.7297H147.683V9.30905H159.59C159.816 9.30905 160 9.1241 160 8.89697V6.83332C160 6.60619 159.816 6.42124 159.59 6.42124L159.593 6.418Z"
        fill="#1D2024"
      />
      <path
        d="M77.5453 14.4585V9.91908C77.5453 9.69195 77.3611 9.507 77.1348 9.507H74.7755C74.7044 9.507 74.6494 9.47131 74.5977 9.43237C74.6397 9.48429 74.6721 9.54269 74.6721 9.61408V11.5739H63.1758V9.30583H74.365C74.4361 9.30583 74.4911 9.34152 74.5428 9.38046C74.504 9.32854 74.4684 9.27014 74.4684 9.19875V6.83334C74.4684 6.60621 74.2842 6.42126 74.058 6.42126H63.0142L60.2864 9.14684V16.5513L63.2793 19.6143H75.5996C75.8259 19.6143 76.0101 19.4294 76.0101 19.2022V17.1418C76.0101 16.9147 75.8259 16.7298 75.5996 16.7298H63.1791V14.4617H77.5518L77.5453 14.4585Z"
        fill="#1D2024"
      />
      <path
        d="M74.5977 9.42911C74.5816 9.4064 74.5654 9.39342 74.5428 9.3772C74.5589 9.39991 74.5751 9.41289 74.5977 9.42911Z"
        fill="#1D2024"
      />
    </Icon>
  )
}
